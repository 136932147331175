const videoCollection = [
  {
    thumb: 'supprelin-removal-procedure-video-thumbnail.png',
    copy: `Hear two experienced professionals share their decades-long experience with <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span>`,
    wistiaID: '8zwkzlkrom',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/8zwkzlkrom?videoFoam=true&doNotTrack=true',
    alt: 'Peer removal discussion video',
  },
  {
    thumb: 'davidlanning_video_thumbnail.png',
    copy: ` Watch an experienced doctor discuss how he uses SUPPRELIN<sup>®</sup> LA`,
    wistiaID: 'eawbrakdin',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/eawbrakdin?videoFoam=true&doNotTrack=true',
    alt: 'Experienced Peer Video',
  },
  {
    thumb: 'lizzy_and_geraldine_thumbnail.png',
    copy: `Watch Lizzy and Geraldine share their CPP journey`,
    wistiaID: '7e26cjcoa9',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/7e26cjcoa9?videoFoam=true&doNotTrack=true',
    alt: 'Lizzie & Geraldine Video',
  },
  {
    thumb: 'share-with-caregivers-thumbnail.png',
    copy: `Share with caregivers to help them better understand how CPP affects their child and about the treatment option, <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span>`,
    wistiaID: 'k3rmh344tp',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/k3rmh344tp?videoFoam=true&doNotTrack=true',
    alt: 'Share with caregivers video',
  },
  {
    thumb: '5goodreasons_video_thumbnail.png',
    copy: `View 5 important reasons to consider SUPPRELIN<sup>®</sup> LA as a treatment option for your appropriate patients`,
    wistiaID: '6w7292pjs8',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/6w7292pjs8?videoFoam=true&doNotTrack=true',
    alt: '5 Important Considerations Video',
  },
  {
    thumb: 'nurse_video_thumbnail.png',
    copy: `Hear from a mother – and nurse – whose child has been treated with <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span>`,
    wistiaID: 'rucsd8tdxv',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/rucsd8tdxv?videoFoam=true&doNotTrack=true',
    alt: 'Mother Nurse Video',
  },
]

export default videoCollection;
