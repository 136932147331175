import React from 'react'
import { Row, Col } from 'react-flexbox-grid';
import Layout from '../../../components/dfa-theme/layout/hcp'
import Collection from '../../../components/collection'
import WistiaVideo from '../../../components/wistia-video'
import SourceEmitter from '../../../lib/emitter'
import HcpVideoCollection from '../../../lib/videos/hcp-videos'

let videoPlayerSubscription = null

const metaTags = {
  description:
    'Access videos for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Video Library | SUPPRELIN® LA (histrelin acetate)',
}

// const externalLinksMetaTags = {
//   category: 'External Link',
//   action: 'Click',
//   label: 'Deerfield Homepage',
// }

class VideoLibraryPage extends React.Component {
  constructor() {
    super()
    this.state = {
      currentVideo: HcpVideoCollection[0],
    }
  }

  componentDidMount() {
    // From collection item
    this.setResponseParams()
    videoPlayerSubscription = SourceEmitter.addListener(
      `onPatientVideoClick`,
      (video) => {
        this.setState({
          currentVideo: video,
        })
      }
    )
  }

  componentWillUnmount() {
    videoPlayerSubscription && videoPlayerSubscription.remove()
  }

  setResponseParams() {
    const responseObj = window.location.hash
      .substr(1)
      .split('&')
      .map((el) => el.split('='))
      .reduce((pre, cur) => {
        pre[cur[0]] = cur[1]
        return pre
      }, {})

    const vidId = responseObj.video
    if (responseObj.video) {
      this.setState({
        currentVideo: HcpVideoCollection[vidId],
      })
    }
  }

  render() {
    const { currentVideo } = this.state

    return (
      <Layout meta={metaTags}>
        <Row>
          <Col xs={12}>
            <h1>Parent and caregiver videos</h1>
          </Col>
        </Row>
        <Row center={'sm'}>
          <Col md={8} lg={10}>
            <WistiaVideo wistiaID={currentVideo.wistiaID} />
          </Col>
        </Row>
        <Row center={'md'}>
          <Col xs={12}>
            <Collection
              centered
              video
              collection={HcpVideoCollection}
              pageName="hcp caregiver resources"
            />
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default VideoLibraryPage;